export default {
  customerForm: null,
  accountHolderContactForm: null,
  accountAddressForm: null,
  customerAddressForm: null,
  customerContactsForm: null,
  verificationFormClone: null,
  settingUserForm: null,
  role: null,
  notification: null,
  settingWarehouse: null,
  settingBrand: null,
  settingPaymentTerm: null,
  settingCategoryForm: null,
  settingCustomerDefaultsForm: null,
  catalogRatesForm: null,
  catalogComponentDefaultsForm: null,
  settingCatalogPriceTiersForm: null,
  customerGeneralForm: null,
  catalogFormClone: null,
  settingCatalogPricingClone: null,
  settingCatalogPricingRulesForm: null,
  settingCatalogDiscountsForm: null,
  paymentMethodsForm: null,
  operationHoursFormClone: null,
  productSubstitutesClone: null,
  productProcurementsClone: null,
  settingsCatalogDefaultsForm: null,
  settingsUserDefaultsForm: null,
  fulfillmentFormClone: null,
  customerVerificationFormClone: null,
  kitFormClone: null,
  phyPropertiesFormClone: null,
  catalogComponentForm: null,
  templateFormClone: null,
  settingFulfillmentLabelFormClone: null,
  settingFulfillmentRulesFormClone: null,
  inventoryFormClone: null,
  assetConditionFormClone: null,
  taxFormClone: null,
  assetTagsFormClone: null,
  assetGeneralFormClone: null,
  rentalSalesFormClone: null,
  settingsDispatchFormClone: null,
  settingsTransferOrdersFormClone: null,
  transferOrderClone: null,
  transferOrderInformationClone: null,
  cardknoxFormClone: null,
}
