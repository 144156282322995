import Vue from 'vue'
import VueRouter from 'vue-router'
import { stringify, parse } from 'qs'
import mainPage from '@/router/main'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...mainPage,
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/icons',
      name: 'icon-list',
    },
  ],
  parseQuery(query) {
    return parse(query)
  },
  stringifyQuery(query) {
    const result = stringify(query)

    return result ? (`?${result}`) : ''
  },

})

// router.beforeEach((to, _, next) => {
//   const isLoggedIn = isUserLoggedIn()
//
//   if (!canNavigate(to) && to.name !== 'auth-logout') {
//   // Redirect to login if not logged in
//     if (!isLoggedIn) return next({ name: 'auth-login' })
//
//     // If logged in => not authorized
//     // return next({ name: 'misc-not-authorized' })
//     return next({ name: 'home' })
//   }
//
//   // Redirect if logged in
//   if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//     const userData = getUserData()
//     next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
//   }
//   return next()
// })

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
