<template>
  <b-form-group
    :label-class="checkRequired(rules) ? 'inputLabel': 'inputLabelNormal'"
    :label="$t(field.label)"
    :label-for="name"
  >
    <validation-provider
      #default="{ errors }"
      :name="$t(field.label || field.placeholder)"
      :rules="rules.rules"
      :vid="name"
    >
      <div class="d-flex align-items-center">
        <b-form-input
          :id="name"
          v-bind="getProps(field)"
          :placeholder="getPlaceholder(field)"
          :value="value"
          :disabled="isEditable"
          @input="$emit('input', $event)"
          @blur="$emit('blur', $event)"
        />
        <slot name="input-extra-el" />
      </div>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormInput, BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { inputFieldsVisibility } from '@/views/components/DynamicForm/mixins'

export default {
  name: 'LTextInput',
  components: {
    BFormInput,
    BFormGroup,
    ValidationProvider,
  },
  mixins: [inputFieldsVisibility],
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isRequiredField: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    rules() {
      let rules = ''
      if (this.isRequiredField) {
        rules += 'required|'
      }
      if (this.field.rules && typeof this.field.rules === 'string') {
        rules += this.field.rules
      }
      if (rules.charAt(rules.length - 1) === '|') {
        rules = rules.slice(0, -1)
      }
      return { rules }
    },
  },
}
</script>
