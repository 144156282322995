import {
  subHours, subMinutes,
} from 'date-fns'

export default {
  WAREHOUSE_LOCATION(state, data) {
    state.warehouseLocations = data
  },
  SET_NOTIFICATIONS_LIST(state, data) {
    state.notificationList = { ...data }
  },
  SET_NOTIFICATION_LIST(state, data) {
    state.notificationList.data.unshift(data)
  },
  SET_NOTIFICATION(state, data) {
    const index = state.notificationList.data.findIndex(notification => notification.id === data.id)
    if (index !== -1) {
      state.notificationList.data.splice(index, 1, data)
    }
  },
  SET_DATE_TIME_OF_WAREHOUSE(state, {
    dateGmt,
    gmtOffSet,
  }) {
    const decodedString = decodeURIComponent(gmtOffSet)

    const dateString = dateGmt
    const gmtOffset = decodedString

    const dt = new Date(dateString)
    const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000)

    // Extract the hours and minutes from the GMT offset
    const offsetSign = gmtOffset[0] === '-' ? -1 : 1
    const offsetParts = gmtOffset.slice(1).split(':')
    const offsetHours = parseInt(offsetParts[0], 10) * offsetSign
    const offsetMinutes = parseInt(offsetParts[1], 10) * offsetSign

    // Adjust the date by subtracting the offset
    let adjustedDate = subHours(dtDateOnly, -offsetHours)
    adjustedDate = subMinutes(adjustedDate, -offsetMinutes)

    state.dateTimeOfWarehouse = adjustedDate
  },
}
