export default {
  brandsList: [],
  categoriesList: [],
  roleList: [],
  warehouseList: [],
  permissionList: [],
  notificationList: [],
  settingNotificationList: [],
  currencyList: [],
  statusList: [
    {
      id: true,
      name: 'Active',
    },
    {
      id: false,
      name: 'Inactive',
    },
  ],
  status01List: [
    {
      id: 1,
      name: 'Active',
    },
    {
      id: 0,
      name: 'Inactive',
    },
  ],
  levelList: [
    {
      id: 1,
      name: 'component',
    },
    {
      id: 2,
      name: 'regular',
    },
  ],
  paymentTermList: [],
  priceTierList: [],
  taxRuleList: [],
  accountManagerList: [],
  deliveryTypeList: [],
  countryList: [],
  timezoneList: [],
  measurementUnitsList: [],
  dateformatList: [],
  customerRulesList: [],
  documentIdList: [
    {
      id: 20,
      name: 'CURRENT_INSURANCE_POLICY',
      label: 'Current insurance policy',
    },
    {
      id: 21,
      name: 'ACCOUNT_HOLDERS_ID',
      label: 'Account holder’s ID (front & back)',
    },
    {
      id: 22,
      name: 'PROOF_OF_ADDRESS',
      label: 'Proof of address',
    },
  ],
  sendEmailFromList: [
    {
      id: 0,
      name: 'Currently logged-in user (sent from the system)',
    },
    {
      id: 1,
      name: 'Company’s billing contact (sent from the system)',
    },
    {
      id: 2,
      name: 'Currently logged-in user',
    },
    {
      id: 3,
      name: 'Company’s billing contact',
    },
  ],
  exportFileEncodingList: [
    {
      id: 'utf_1',
      name: 'Utf-8',
    },
    {
      id: 'utf_2',
      name: 'Windows 1250',
    },
    {
      id: 'utf_3',
      name: 'Windows 1251',
    },
  ],
  receiveMethodsList: [
    {
      id: 'web',
      name: 'Web',
    },
    {
      id: 'email',
      name: 'Email',
    },
    {
      id: 'phone',
      name: 'Phone',
    },
    {
      id: 'walk_on',
      name: 'Walk On',
    },
  ],
  orderDispatchContactList: [],
  orderPlacedList: [],
  dispatchAndReturnMethodsList: [],
  dispatchReceivingMethodsList: [
    {
      id: 'web',
      name: 'Web',
    },
    {
      id: 'email',
      name: 'Email',
    },
    {
      id: 'phone',
      name: 'Phone',
    },
    {
      id: 'walk_on',
      name: 'Walk On',
    },
  ],
  issueTypeList: [
    {
      id: 'Usable',
      name: 'Usable',
    },
    {
      id: 'Disabled',
      name: 'Disabled',
    },
    {
      id: 'Cosmetic',
      name: 'Cosmetic',
    },
    {
      id: 'Maintenance',
      name: 'Maintenance',
    },
  ],
  stateList: [],
  attachmentCategoryListVerification: [],
  attachmentCategoryListContact: [],
  attachmentCategoryListCatalog: [],
  attachmentCategoryListAssets: [],
  attachmentCategoryListInventoryAssetDamaged: [],
  languageList: [],
  settingsDateFormat: null,
  paymentOptionList: [],
  monthListInText: [
    {
      monthText: 'jan',
      monthNumber: '01',
    },
    {
      monthText: 'feb',
      monthNumber: '02',
    },
    {
      monthText: 'mar',
      monthNumber: '03',
    },
    {
      monthText: 'apr',
      monthNumber: '04',
    },
    {
      monthText: 'may',
      monthNumber: '05',
    },
    {
      monthText: 'jun',
      monthNumber: '06',
    },
    {
      monthText: 'jul',
      monthNumber: '07',
    },
    {
      monthText: 'aug',
      monthNumber: '08',
    },
    {
      monthText: 'sep',
      monthNumber: '09',
    },
    {
      monthText: 'oct',
      monthNumber: '10',
    },
    {
      monthText: 'nov',
      monthNumber: '11',
    },
    {
      monthText: 'dec',
      monthNumber: '12',
    },
  ],
  organizationData: {},
  tagsList: [],
  containerType: [],
  measurementCategoriesList: [],
  productTypesList: [],
  pricingTypesList: [],
  integrationsList: [],
  pricingRulesList: [],
  catalogPricingRulesList: [],
  fullfilBarcodeScannerList: [],
  contactTypes: [
    {
      text: 'Billing ',
      value: 'is_billing',
    },
    {
      text: 'Shipping',
      value: 'is_shipping',
    },
    {
      text: 'Account Holder',
      value: 'is_account_holder',
    },
    {
      text: 'Can Place Orders',
      value: 'can_place_orders',
    },
  ],
  warehouseLocationsList: [],
  productTypeProducts: 'products',
  productTypeComponents: 'components',
  productTypeKits: 'kits',
  productTypeContainers: 'containers',
  productTypeNonInventory: 'nonInventory',
  productStocks: 'stocks',
  productNonStocks: 'nonStocks',
  settingsCatalogPricingRules: [],
  settings_pricing_rules_pricing_month_by: [],
  settings_pricing_rules_base_price_selection: [],
  settings_pricing_rules_prorate_month: [],
  settings_pricing_rules_change_charge_end_time_return: [],
  settings_pricing_rules_change_charge_start_time_ship: [],
  settings_pricing_rules_default_unit: [],
  settings_pricing_rules_compute_total_cost_for: [],
  settings_pricing_rules_labor_cost_based_on: [],
  settings_pricing_rules_equip_cost_based_on: [],
  settings_pricing_rules_misc_cost_based_on: [],
  settings_pricing_rules_po_price_items_parts: [],
  settings_pricing_rules_po_price_misc: [],
  settings_pricing_rules_po_price_subrent: [],
  settings_pricing_rules_po_price_inventory: [],
  operationHours: [
    {
      numericHour: 0,
      value: '12:00 am',
      text: '12:00 am',
    },
    {
      numericHour: 0.3,
      value: '12:30 am',
      text: '12:30 am',
    },
    {
      numericHour: 1,
      value: '1:00 am',
      text: '1:00 am',
    },
    {
      numericHour: 1.3,
      value: '1:30 am',
      text: '1:30 am',
    },
    {
      numericHour: 2,
      value: '2:00 am',
      text: '2:00 am',
    },
    {
      numericHour: 2.3,
      value: '2:30 am',
      text: '2:30 am',
    },
    {
      numericHour: 3,
      value: '3:00 am',
      text: '3:00 am',
    },
    {
      numericHour: 3.3,
      value: '3:30 am',
      text: '3:30 am',
    },
    {
      numericHour: 4,
      value: '4:00 am',
      text: '4:00 am',
    },
    {
      numericHour: 4.3,
      value: '4:30 am',
      text: '4:30 am',
    },
    {
      numericHour: 5,
      value: '5:00 am',
      text: '5:00 am',
    },
    {
      numericHour: 5.3,
      value: '5:30 am',
      text: '5:30 am',
    },
    {
      numericHour: 6,
      value: '6:00 am',
      text: '6:00 am',
    },
    {
      numericHour: 6.3,
      value: '6:30 am',
      text: '6:30 am',
    },
    {
      numericHour: 7,
      value: '7:00 am',
      text: '7:00 am',
    },
    {
      numericHour: 7.3,
      value: '7:30 am',
      text: '7:30 am',
    },
    {
      numericHour: 8,
      value: '8:00 am',
      text: '8:00 am',
    },
    {
      numericHour: 8.3,
      value: '8:30 am',
      text: '8:30 am',
    },
    {
      numericHour: 9,
      value: '9:00 am',
      text: '9:00 am',
    },
    {
      numericHour: 9.3,
      value: '9:30 am',
      text: '9:30 am',
    },
    {
      numericHour: 10,
      value: '10:00 am',
      text: '10:00 am',
    },
    {
      numericHour: 10.3,
      value: '10:30 am',
      text: '10:30 am',
    },
    {
      numericHour: 11,
      value: '11:00 am',
      text: '11:00 am',
    },
    {
      numericHour: 11.3,
      value: '11:30 am',
      text: '11:30 am',
    },
    {
      numericHour: 12,
      value: '12:00',
      text: '12:00 pm',
    },
    {
      numericHour: 12.3,
      value: '12:30 pm',
      text: '12:30 pm',
    },
    {
      numericHour: 13,
      value: '1:00 pm',
      text: '1:00 pm',
    },
    {
      numericHour: 13.3,
      value: '1:30 pm',
      text: '1:30 pm',
    },
    {
      numericHour: 14,
      value: '2:00 pm',
      text: '2:00 pm',
    },
    {
      numericHour: 14.3,
      value: '2:30 pm',
      text: '2:30 pm',
    },
    {
      numericHour: 15,
      value: '3:00 pm',
      text: '3:00 pm',
    },
    {
      numericHour: 15.3,
      value: '3:30 pm',
      text: '3:30 pm',
    },
    {
      numericHour: 16,
      value: '4:00 pm',
      text: '4:00 pm',
    },
    {
      numericHour: 16.3,
      value: '4:30 pm',
      text: '4:30 pm',
    },
    {
      numericHour: 17,
      value: '5:00 pm',
      text: '5:00 pm',
    },
    {
      numericHour: 17.3,
      value: '5:30 pm',
      text: '5:30 pm',
    },
    {
      numericHour: 18,
      value: '6:00 pm',
      text: '6:00 pm',
    },
    {
      numericHour: 18.3,
      value: '6:30 pm',
      text: '6:30 pm',
    },
    {
      numericHour: 19,
      value: '7:00 pm',
      text: '7:00 pm',
    },
    {
      numericHour: 19.3,
      value: '7:30 pm',
      text: '7:30 pm',
    },
    {
      numericHour: 20,
      value: '8:00 pm',
      text: '8:00 pm',
    },
    {
      numericHour: 20.3,
      value: '8:30 pm',
      text: '8:30 pm',
    },
    {
      numericHour: 21,
      value: '9:00 pm',
      text: '9:00 pm',
    },
    {
      numericHour: 21.3,
      value: '9:30 pm',
      text: '9:30 pm',
    },
    {
      numericHour: 22,
      value: '10:00 pm',
      text: '10:00 pm',
    },
    {
      numericHour: 22.3,
      value: '10:30 pm',
      text: '10:30 pm',
    },
    {
      numericHour: 23,
      value: '11:00 pm',
      text: '11:00 pm',
    },
    {
      numericHour: 23.3,
      value: '11:30 pm',
      text: '11:30 pm',
    },
  ],
  cardTypeList: [
    { value: 0, text: 'Credit' },
    { value: 1, text: 'Debit' },
  ],
  verificationDefaultStatus: [
    { id: 0, text: 'Not Verified' },
    { id: 1, text: 'Verified' },
    { id: 2, text: 'Do Not Rent' },
    { id: 3, text: 'Incomplete' },
  ],
  unitTypeList: [],
  supplierList: [],
  customersList: [],
  assetConditionList: [],
  insuranceCompanyNameList: [],
  operationHoursDisableList: [],
  billingContact: [],
  shippingContact: [],
  dispatchAndReturnAddress: [],
  billingContactOnPurchase: [],
  isLoadingOperationHoursDisableList: false,
  warehousesAddressList: [],
  ecommerceShippingMethod: [],
  orderDispatchList: [],
}
