// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/no-cycle
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import jwtDefaultConfig from './jwtDefaultConfig'
// eslint-disable-next-line import/no-cycle

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => {
        const { timezone, warehouse } = response.headers
        if (timezone) store.commit('app/SET_TIME_ZONE', timezone)
        if (warehouse) store.commit('app/SET_WAREHOUSE', JSON.parse(warehouse))

        // const userPermissionsLastModifiedDate = JSON.parse(localStorage.getItem(this.jwtConfig.userPermissionsLastModifiedKeyName))
        // const responseDataHeaders = response.headers.user_permissions_last_modified

        // if (!userPermissionsLastModifiedDate !== responseDataHeaders) {
        //   this.setUserPermissionsData(response.data, response.headers)
        //   this.$ability.update(response.data.permissions)
        // }

        return response
      },
      error => {
        // const { config, response: { status } } = error
        const { response } = error
        if (response && response.status === 401) {
          this.clearUserStorage()
        }
        return Promise.reject(error)
      },
    )
  }

  fetchUserData() {
    return new Promise((resolve, reject) => {
      this.axiosIns.get(this.jwtConfig.userDataEndpoint).then(response => {
        this.setUserData(JSON.stringify(response.data))
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  }

  getThemeColorForHeader() {
    return localStorage.getItem(this.jwtConfig.storageOrganizationPrimaryThemeColor)
  }

  setThemeColorForHeader(value) {
    const correctedFormat = value.replace(/"/g, '')
    return localStorage.setItem(this.jwtConfig.storageOrganizationPrimaryThemeColor, correctedFormat)
  }

  // start .The next line is made for Discard changes modal for outer items. Now working for Account Create page.
  getFormChangesOnForm() {
    return localStorage.getItem(this.jwtConfig.storageFormChangeKeyName)
  }

  setFormChangesOnForm(value) {
    return localStorage.setItem(this.jwtConfig.storageFormChangeKeyName, value)
  }
  // end

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setUserData(value) {
    localStorage.setItem(this.jwtConfig.storageUserDataKeyName, value)
    localStorage.setItem(this.jwtConfig.storageOrganizationRequiresEditConfirmationKeyName, JSON.parse(value).warehouse.organization.requires_edit_confirmation)
  }

  setUserPermission(value) {
    localStorage.setItem(this.jwtConfig.storageUserPermissionKeyName, value)
  }

  setUserPermissionsLastModified(value) {
    localStorage.setItem(this.jwtConfig.userPermissionsLastModifiedKeyName, value ?? Date.now())
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  afterLogin(ctx, response) {
    this.setAll(response.data, response.headers)
    this.setUserPermissionsData(response.data, response.headers)

    // response.data.permissions  manageAll
    ctx.$ability.update(response.data.permissions)
    const profileData = response.data.profile

    // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
    ctx.$router.replace(getHomeRouteForLoggedInUser())
      .then(() => {
        ctx.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Welcome ${profileData.firstname} ${profileData.lastname}`,
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'You have successfully logged in. Now you can start to explore!',
          },
        })
      })
  }

  forgotPassword(...args) {
    return this.axiosIns.post(this.jwtConfig.forgotPasswordEndpoint, ...args)
  }

  restorePassword(...args) {
    return this.axiosIns.post(this.jwtConfig.restorePasswordEndpoint, ...args)
  }

  // eslint-disable-next-line class-methods-use-this
  logout(...args) {
    // Logout from backend
    return this.axiosIns.post(this.jwtConfig.logoutEndpoint, ...args)
  }

  clearUserStorage() {
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageFormChangeKeyName)
    localStorage.removeItem(this.jwtConfig.storageOrganizationPrimaryThemeColor)
    // Remove userData from localStorage
    localStorage.removeItem(this.jwtConfig.storageUserDataKeyName)
    localStorage.removeItem(this.jwtConfig.storageOrganizationRequiresEditConfirmationKeyName)
    localStorage.removeItem(this.jwtConfig.storageUserPermissionKeyName)
    localStorage.removeItem(this.jwtConfig.userPermissionsLastModifiedKeyName)
    localStorage.removeItem(this.jwtConfig.settingCustomerDefaultFormKeyName)
    localStorage.removeItem(this.jwtConfig.storageOrganizationDateFormat)
    // if (router.currentRoute.name !== 'auth-login') {
    //   router.go(0)
    // }
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  // keyCloak
  redirectKeyCloakAuthUrl() {
    return new Promise((resolve, reject) => {
      this.axiosIns.post(this.jwtConfig.keyCloakAuthUrl, { redirect_uri: this.jwtConfig.keyCloakRedirectUrl })
        .then(response => {
          window.location.href = response.data.data.url
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  loginKeyCloak(code) {
    return this.axiosIns.post(this.jwtConfig.keyCloakLoginUrl, {
      code,
      redirect_uri: this.jwtConfig.keyCloakRedirectUrl,
    })
  }

  confirmEmail(args) {
    const { confirm, mail } = args
    return this.axiosIns.get(this.jwtConfig.confirmEmailEndpoint, {
      params: {
        confirm,
        mail,
      },
    })
  }

  setAll(responseData) {
    this.setToken(responseData[this.jwtConfig.storageTokenKeyName])
    this.setUserData(JSON.stringify(responseData.profile))
    this.setThemeColorForHeader(JSON.stringify(responseData.system_behavior_defaults))
    store.dispatch('app/settingCustomerDefault').then(response => {
      localStorage.setItem(jwtDefaultConfig.settingCustomerDefaultFormKeyName, JSON.stringify(response.data.data))
      localStorage.setItem(jwtDefaultConfig.storageOrganizationDateFormat, JSON.stringify(response.data.data.warehouse?.organization?.def_date_format))
    })

    store.commit('app/SET_ORGANIZATION_REQUIRES_EDIT_CONFIRMATION', localStorage.getItem(jwtDefaultConfig.storageOrganizationRequiresEditConfirmationKeyName) === 'true')
    // return this.fetchUserData()
  }

  setUserPermissionsData(responseData, headersData) {
    this.setUserPermission(JSON.stringify(responseData.permissions))
    this.setUserPermissionsLastModified(JSON.stringify(headersData.user_permissions_last_modified))
  }
}
