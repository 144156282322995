import { $themeBreakpoints } from '@themeConfig'
import axios from '@/libs/axios'
import jwtDefaultConfig from '@/auth/jwt/jwtDefaultConfig'

export default {
  namespaced: true,
  state: {
    organizationRequiresEditConfirmation: localStorage.getItem(jwtDefaultConfig.storageOrganizationRequiresEditConfirmationKeyName) === 'true',
    windowWidth: 0,
    mobileWindowWidth: 800,
    shallShowOverlay: false,
    timezone: 'America/New_York',
    warehouse: {},
    warehousePermissions: {},
    appInfo: [
      '212-243-RENT (7368)',
      '718-366-RENT (7368)',
      'www.csirentals.com',
    ],
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    SET_TIME_ZONE(state, val) {
      state.timezone = val
    },
    SET_WAREHOUSE(state, val) {
      state.warehouse = val
    },
    SET_PERMISSIONS(state, val) {
      state.warehousePermissions = val
    },
    SET_ORGANIZATION_REQUIRES_EDIT_CONFIRMATION(state, val) {
      state.organizationRequiresEditConfirmation = val
    },
  },
  actions: {
    getMe() {
      return axios.get('users/me/permissions')
    },
    settingCustomerDefault() {
      return axios.get('/settings/customers/default')
    },
  },
}
