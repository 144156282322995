<template>
  <b-form-group
    :label-class="checkRequired(field) ? 'inputLabel' : 'inputLabelNormal'"
    :label="$t(field.label)"
    :label-for="name"
  >
    <validation-provider
      #default="{ errors }"
      :name="$t(field.label || field.placeholder)"
      :rules="$t(field.rules)"
      :vid="name"
    >
      <div class="d-flex align-center">
        <v-select
          class="selectInput flex-grow-1"
          placeholder="Select..."
          :value="value"
          :class="`v-select--custom ${ isEditable ? 'v-select--disabled' : ''}`"
          v-bind="getProps()"
          :options="selectOptions"
          :loading="isSelectLoading"
          :disabled="isEditable"
          @search="onSearch"
          @input="emitData"
        />
        <slot name="after-input" />
      </div>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import vSelect from 'vue-select'
import { BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { inputFieldsVisibility } from '@/views/components/DynamicForm/mixins'

function debounce(func, timeout) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => func(...args), timeout)
  }
}

export default {
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  mixins: [inputFieldsVisibility],
  props: {
    field: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    parentValue: {
      required: true,
      default: null,
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: null,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    parentValue(newValue, oldValue) {
      if (newValue === oldValue) return
      if (newValue === null) {
        this.emitData(null)
        this.selectOptions = []
        return
      }
      if (newValue && oldValue) {
        this.emitData(null)
      }
      this.getSelectOptions({ parentValue: newValue, token: this.$route.query.token ?? '' })
    },
  },
  mounted() {
    if (this.field.store && this.parentValue !== null) {
      this.getSelectOptions({ parentValue: this.parentValue, token: this.$route.query.token ?? '' })
    }
  },
  methods: {
    getProps() {
      return {
        label: 'name',
        ...(this.field.options ?? {}),
      }
    },
    getSelectOptions(params) {
      this.isSelectLoading = true
      this.$store
        .dispatch(`listModule/${this.field.store}`, params)
        .then(res => {
          const body = res.data.data
          if (body && body.per_page) {
            this.isSearchable = true
          }
          this.selectOptions = this.$store.state.listModule[this.field.store]
          if (!this.value && this.field.firstSelect) {
            this.emitData(this.selectOptions[0])
          }
        })
        .finally(() => {
          this.isSelectLoading = false
        })
    },
    onSearch(searchQuery, loading) {
      if (this.isSearchable && searchQuery.length) {
        loading(true)
        this.search(searchQuery, this)
      }
    },
    search: debounce((searchQuery, ctx) => {
      ctx.getSelectOptions({ name: searchQuery, parentValue: ctx.parentValue })
    }, 1000),
    emitData(selectedValue) {
      this.$emit('input', selectedValue)
    },
    checkRequired(field) {
      if (!field.rules) return false
      return field.rules.split('|').includes('required')
    },
  },
}
</script>
<style lang="scss">
.v-select--custom {
  #vs1__combobox {
    height: 38px;
  }

  input::placeholder {
    color: #A0A0A0;
  }

  .vs__actions {
    padding-top: 0 !important;
  }

  .vs__selected {
    margin-top: 0 !important;
    height: 100%;
  }
}

.v-select--disabled {
  .vs__dropdown-toggle,
  .vs__search,
  .vs__clear {
    background: #efefef !important;
  }

  .vs__actions {
    padding-top: 0 !important;
    .vs__clear, svg {
      background: #efefef !important;
    }
  }

  .vs__selected {
    margin-top: 0 !important;
    height: 100%;
  }
}
</style>
