import home from './home/home'
import customers from './customers/customers'

export default [
  {
    path: '/',
    name: 'main-page',
    component: () => import('@/views/main/Main.vue'),
    redirect: { name: 'home-customers-create' },
    children: [
      // ...home,
      ...customers,
    ],
  },
]
