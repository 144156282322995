<template>
  <b-form-group
    :label-class="checkRequired(rules) ? 'inputLabel': 'inputLabelNormal'"
    :label="$t(field.label)"
    :label-for="name"
  >
    <validation-provider
      #default="{errors}"
      :name="$t(field.label || field.placeholder)"
      :rules="rules.rules"
      :vid="name"
    >
      <v-select
        placeholder="Select..."
        :value="value"
        :class="`v-select--custom ${ isEditable ? 'v-select--disabled' : ''}`"
        v-bind="getProps()"
        :options="selectOptions"
        :loading="isSelectLoading"
        :disabled="isEditable"
        @search="onSearch"
        @input="onSelect"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import vSelect from 'vue-select'
import { BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { inputFieldsVisibility } from '@/views/components/DynamicForm/mixins'
import { debounce } from '@core/utils/utils'
import { mergeDeep } from '@core/components/b-table-responsive/table-responsive/table/utils/object'

export default {
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  mixins: [inputFieldsVisibility],
  props: {
    field: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: null,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isRequiredField: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    rules() {
      let rules = ''
      if (this.isRequiredField && (!this.field.rules || !this.field.rules.includes('required'))) {
        rules += 'required|'
      }
      if (this.field.rules && typeof this.field.rules === 'string') {
        rules += this.field.rules
      }
      if (rules.charAt(rules.length - 1) === '|') {
        rules = rules.slice(0, -1)
      }
      return { rules }
    },
  },
  mounted() {
    if (this.field.store) {
      this.getSelectOptions(this.field.fParams)
    }
  },
  methods: {
    getProps() {
      return {
        label: 'name',
        ...(this.field.options ?? {}),
      }
    },
    getSelectOptions(params) {
      const methodName = this.field.store.indexOf('/') === -1 ? `listModule/${this.field.store}` : this.field.store
      // eslint-disable-next-line no-underscore-dangle
      const actionExists = Object.keys(this.$store._actions).findIndex(key => key === methodName) !== -1
      if (actionExists) {
        this.isSelectLoading = true
        this.$store.dispatch(methodName, params)
          .then(res => {
            this.selectOptions = this.field.getList ? this.field.getList(res) : this.$store.state.listModule[this.field.store]
            if (params === null && !this.value && this.field.firstSelect) {
              this.onSelect(this.selectOptions[0])
            }
            if (!res) return
            const body = res.data.data
            if (body && body.per_page) {
              this.isSearchable = true
            }
          })
          .finally(() => {
            this.isSelectLoading = false
          })
      } else {
        this.selectOptions = this.field.getList ? this.field.getList() : this.$store.state.listModule[this.field.store]
      }
    },
    onSelect(e) {
      this.$emit('input', e)
    },
    onSearch(searchQuery, loading) {
      this.$emit('search', searchQuery)
      if (this.isSearchable) {
        loading(true)

        this.search(searchQuery, this)
      }
    },
    search: debounce((searchQuery, ctx) => {
      const query = mergeDeep({ [ctx.field.hasOwnProperty('filterBy') ? ctx.field.filterBy : 'search']: searchQuery }, ctx.field.fSearchParams)
      searchQuery.length > 0
        ? ctx.getSelectOptions(query)
        : ctx.getSelectOptions()
    }, 1000),

  },
}

</script>
<style lang="scss">
.vs__dropdown-toggle {
  transition: none !important;
}

.v-select--custom {
  #vs1__combobox {
    height: 38px;
  }

  input::placeholder {
    color: #A0A0A0;
  }

  .vs__actions {
    padding-top: 0 !important;
  }

  .vs__selected {
    margin-top: 0 !important;
    height: 100%;
  }
}

.v-select--disabled {
 .vs__dropdown-toggle,
 .vs__search,
 .vs__clear {
    background: #efefef !important;
 }

  .vs__actions {
      padding-top: 0 !important;
    .vs__clear, svg {
      background: #efefef !important;
    }
  }

  .vs__selected {
    margin-top: 0 !important;
    height: 100%;
  }
}

.vs__open-indicator {
    top: -1px;
    position: relative;
}
</style>
