<template>
  <div>
    <div>
      <b-row>
        <!--        <b-col-->
        <!--          v-if="menuItems.length > 0"-->
        <!--          :md="menuItems.length > 0 ? 2: 0"-->
        <!--          class="pr-0 sub-menu-parent"-->
        <!--        >-->
        <!--          <b-list-group class="sub-menu-list">-->
        <!--            <b-list-group-item-->
        <!--              v-for="menu in menuItems"-->
        <!--              v-if="canViewHorizontalNavMenuHeaderLink(menu)"-->
        <!--              :key="menu.name"-->
        <!--              v-bind="getProps(menu)"-->
        <!--              class="sub-menu-name"-->
        <!--            >-->
        <!--              {{ menu.layout || menu.name }}-->
        <!--            </b-list-group-item>-->
        <!--          </b-list-group>-->
        <!--        </b-col>-->
        <!--        :md="menuItems.length > 0 ? 10 : 12"-->
        <b-col
          md="12"
          class="pl-0"
        >
          <b-card class="sub-menu-card">
            <slot />
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BListGroup, BListGroupItem, BRow,
  BCol, BCard,
} from 'bootstrap-vue'
import { useUtils as useAclUtils } from '@core/libs/acl'

export default {
  name: 'SubMenuComponent',
  components: {
    // BListGroup,
    // BListGroupItem,
    BRow,
    BCol,
    BCard,
  },
  props: {
    menuItems: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
  },
  methods: {
    getProps(menu) {
      const props = {
        to: menu.route,
        active: menu.active,
        disabled: menu.disabled,
      }
      if (menu.disabled) delete props.to
      return props
    },
  },
  setup() {
    const { canViewHorizontalNavMenuHeaderLink } = useAclUtils()

    return {
      canViewHorizontalNavMenuHeaderLink,
    }
  },
}
</script>

<style scoped>
@media all and (max-width: 768px) {
  .sub-menu-parent {
    display: block;
    flex-direction: row;
    justify-content: space-between;
    width: 100% !important;
    padding: 0 !important;
  }
  .sub-menu-name {
    max-height: 40px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
}

</style>
