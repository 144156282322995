<template>
  <b-form-group
    :label-class="checkRequired(field) ? 'inputLabel' : 'inputLabelNormal'"
    :label="$t(field.label)"
    :label-for="name"
  >
    <validation-provider
      #default="{ errors }"
      :name="$t(field.label || field.placeholder)"
      :rules="field.rules"
      :vid="name"
    >
      <div
        v-if="isFinished"
        :class="`${hasCalendarIcon ? 'flatpickr-input--custom-style':''} ${isEditable ? 'flatpickr-input--disabled': ''}`"
      >
        <feather-icon
          v-if="hasCalendarIcon"
          icon="LCalendarsIcon"
          size="16"
          class="flatpickr-input--calendar"
        />
        <flat-picker
          :id="`flat-${name}`"
          :ref="'flatPicker'"
          :placeholder="settingsDateFormatLabel"
          :value="value"
          v-bind="getProps()"
          :config="getConfig"
          style="height: 36px"
          :class="`form-control flatpickr-input`"
          :disabled="isEditable"
          @on-change="onChange"
          @keyup="onChange"
        />
      </div>
      <div
        v-else
        class="form-control"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import flatPicker from 'vue-flatpickr-component'
import { inputFieldsVisibility } from '@/views/components/DynamicForm/mixins'

export default {
  name: 'LFlatPicker',
  components: {
    BFormGroup,
    ValidationProvider,
    flatPicker,
  },
  mixins: [inputFieldsVisibility],
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number, Date],
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    isCalendarIcon: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => {},
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataFormat: null,
      flatPickerConfigs: null,
    }
  },
  computed: {
    settingsDateFormatLabel() {
      return this.$store.state.listModule?.settingsDateFormat?.label
    },
    settingsDateFormat() {
      return this.$store.state.listModule?.settingsDateFormat?.label.split('/').map(formatItem => formatItem[0]).join('/')
    },
    settingsDateAltFormat() {
      const format = this.$store.state.listModule?.settingsDateFormat?.label.split('/').map(item => item.split(''))
      // const dateFormatOrder = this.settingsDateFormat?.split('/')
      const dateAltFormatOrder = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < format?.length; i++) {
        if (format[i][0] === 'y' && format[i].length === 4) {
          dateAltFormatOrder.push('Y')
        } else if (format[i][0] === 'y' && format[i].length === 2) {
          dateAltFormatOrder.push('y')
        } else if (format[i][0] === 'M' && format[i].length === 2) {
          dateAltFormatOrder.push('M')
        } else if (format[i][0] === 'd') {
          dateAltFormatOrder.push('d')
        }
      }

      return dateAltFormatOrder.join('/')
    },
    hasCalendarIcon() {
      return this.getConfig['is-calendar-icon']
    },
    operationHoursDisableList() {
      return this.$store.state.listModule.operationHoursDisableList
    },
    isLoadingOperationHoursDisableList() {
      return this.$store.state.listModule.isLoadingOperationHoursDisableList
    },
    isFinished() {
      if (this.field.hasOperationHours) {
        return this.settingsDateFormatLabel && this.isLoadingOperationHoursDisableList
      }
      return this.settingsDateFormatLabel
    },
    getConfig() {
      const customConfig = {
        ...{
          static: true,
          mode: 'single',
          altInput: true,
          altFormat: this.settingsDateAltFormat,
          dateFormat: this.settingsDateAltFormat ?? ['Y', 'm', 'd'],
          'is-calendar-icon': false,
        },
        ...(this.field.options.config ?? {}),
      }

      if (this.field.hasOperationHours) {
        customConfig.disable = this.operationHoursDisableList
      }
      return customConfig
    },
  },
  created() {
    this.flatPickerConfigs = this.getProps()
    this.$store.dispatch('listModule/settingsDateFormat')
    if (this.field.hasOperationHours) {
      this.$store.dispatch('listModule/operationHoursDisableList')
    }
  },
  methods: {
    getProps() {
      const options = { ...this.field.options } ?? {}
      delete options.config
      return options
    },
    onChange(a) {
      const date = a[0]
      if (date) { this.$emit('input', date.getTime()) }
      this.$emit('keyup', a)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.flatpickr-input--custom-style {
  position: relative;

  & .flatpickr-input {
    padding-left: 30px !important;

    &--disabled {
      background: #efefef !important;
    }
  }

  & .flatpickr-input--calendar {
    position: absolute;
    top: 10px;
    left: 8px;
  }
}

.flatpickr-input--disabled {
   background: #efefef !important;
    pointer-events: none;
}
</style>
