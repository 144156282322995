export default {
  SET_ROLE_LIST(state, data) {
    state.roleList = data
  },
  SET_WAREHOUSE_LIST(state, data) {
    state.warehouseList = data
  },
  SET_PERMISSION_LIST(state, data) {
    data.forEach(item => {
      const { permissions } = item
      permissions.forEach(permission => {
        // eslint-disable-next-line no-param-reassign
        permission.checked = false
      })
    })
    state.permissionList = data
  },
  SET_NOTIFICATION_LIST(state, data) {
    data.forEach(item => {
      const { triggers } = item
      triggers.forEach(notification => {
        // eslint-disable-next-line no-param-reassign
        notification.checked = false
      })
    })
    state.notificationList = data
  },
  SET_SETTING_NOTIFICATION_LIST(state, data) {
    state.settingNotificationList = data.data
  },
  SET_CURRENCY_LIST(state, data) {
    state.currencyList = data
  },
  SET_PAYMENT_TERM_LIST(state, data) {
    state.paymentTermList = data
  },
  SET_PRICE_TIER_LIST(state, data) {
    state.priceTierList = data
  },
  SET_TAX_RULE_LIST(state, data) {
    state.taxRuleList = data
  },
  SET_ACCOUNT_MANAGER_LIST(state, data) {
    state.accountManagerList = data
  },
  SET_ORDERS_DISPATCH_LIST(state, data) {
    state.orderDispatchList = data.map(item => ({ ...item, full_name: `${item.firstname} ${item.lastname}` }))
  },
  SET_ORDERS_PLACED_BY_LIST(state, data) {
    state.orderPlacedList = data.map(item => ({ ...item, full_name: `${item.firstname} ${item.lastname}` }))
  },
  SET_ORDER_DISPATCH_CONTACT_LIST(state, data) {
    state.orderDispatchContactList = data.map(item => ({
      ...item, full_name: `${item.first_name} ${item.last_name}`,
    }))
  },
  SET_ORDER_DISPATCH__RETURN_METHODS_LIST(state, data) {
    state.dispatchAndReturnMethodsList = data
  },
  SET_DELIVERY_TYPE_LIST(state, data) {
    state.deliveryTypeList = data
  },
  SET_COUNTRY_LIST(state, data) {
    state.countryList = data
  },
  SET_TIMEZONE_LIST(state, data) {
    state.timezoneList = data
  },
  SET_DATEFORMAT_LIST(state, data) {
    state.dateformatList = data
  },
  SET_MEASUREMENT_UNITS_LIST(state, data) {
    state.measurementUnitsList = data
  },
  SET_STATE_LIST(state, data) {
    state.stateList = data
  },
  SET_BRANDS_LIST(state, data) {
    state.brandsList = data
  },
  SET_CATEGORIES_LIST(state, data) {
    // eslint-disable-next-line no-param-reassign
    data = data.map(item => {
      // eslint-disable-next-line no-param-reassign
      item.label = item.name
      // eslint-disable-next-line no-param-reassign
      delete item.name
      return item
    })
    state.categoriesList = data
  },
  SET_ATTACHMENT_CATEGORY_LIST_VERIFICATION(state, data) {
    state.attachmentCategoryListVerification = Object.entries(data).map(([key, val]) => ({
      name: val,
      id: key,
    }))
  },
  SET_ATTACHMENT_CATEGORY_LIST_CONTACT(state, data) {
    state.attachmentCategoryListContact = Object.entries(data).map(([key, val]) => ({
      name: val,
      id: key,
    }))
  },
  SET_ATTACHMENT_CATEGORY_LIST_CATALOG(state, data) {
    state.attachmentCategoryListCatalog = Object.entries(data).map(([key, val]) => ({
      name: val,
      id: key,
    }))
  },
  SET_ATTACHMENT_CATEGORY_LIST_ASSETS(state, data) {
    state.attachmentCategoryListAssets = Object.entries(data).map(([key, val]) => ({
      name: val,
      id: key,
    }))
  },
  SET_ATTACHMENT_CATEGORY_LIST_ASSETS_DAMAGED(state, data) {
    state.attachmentCategoryListInventoryAssetDamaged = Object.entries(data).map(([key, val]) => ({
      name: val,
      id: key,
    }))
  },
  SET_LANGUAGES_LIST(state, data) {
    state.languageList = data
  },
  SET_SETTINGS_DATE_FORMAT(state, data) {
    state.settingsDateFormat = data
  },
  SET_PAYMENT_OPTIONS(state, data) {
    // eslint-disable-next-line no-unused-expressions
    data?.forEach(item => {
      // eslint-disable-next-line no-param-reassign
      item.checked = false
    })
    state.paymentOptionList = data
  },
  SET_CUSTOMER_RULES(state, data) {
    // eslint-disable-next-line no-unused-expressions
    data?.forEach(item => {
      // eslint-disable-next-line no-param-reassign
      item.checked = false
    })
    state.customerRulesList = data
  },
  SET_ORGANIZATION_DATA(state, data) {
    state.organizationData = { ...data }
  },
  SET_TAGS_DATA(state, data) {
    state.tagsList = data
  },
  SET_CONTAINER_TYPE_LIST(state, data) {
    state.containerType = data
  },
  SET_MEASUREMENT_CATEGORIES_LIST(state, data) {
    state.measurementCategoriesList = data
  },
  SET_PRODUCT_TYPES_DATA(state, data) {
    state.productTypesList = data
  },
  SET_PRICING_TYPES_DATA(state, data) {
    state.pricingTypesList = data
  },
  SET_PAYMENT_GATEWAYS(state, data) {
    state.integrationsList = data
  },
  SET_PRICING_RULES_DATA(state, data) {
    state.pricingRulesList = data
  },
  SET_CATALOG_PRICING_RULES(state, data) {
    state.catalogPricingRulesList = data
  },
  SET_WAREHOUSE_LOCATION_DATA(state, data) {
    state.warehouseLocationsList = data
  },
  SETTINGS_PRICING_RULES_PRICING_MONTH_BY(state, data) {
    state.settings_pricing_rules_pricing_month_by = data
  },
  SETTINGS_PRICING_RULES_BASE_PRICE_SELECTION(state, data) {
    state.settings_pricing_rules_base_price_selection = data
  },
  SETTINGS_PRICING_RULES_PRORATE_MONTH(state, data) {
    state.settings_pricing_rules_prorate_month = data
  },
  SETTINGS_PRICING_RULES_CHANGE_CHARGE_END_TIME_RETURN(state, data) {
    state.settings_pricing_rules_change_charge_end_time_return = data
  },
  SETTINGS_PRICING_RULES_CHANGE_CHARGE_START_TIME_SHIP(state, data) {
    state.settings_pricing_rules_change_charge_start_time_ship = data
  },
  SETTINGS_PRICING_RULES_DEFAULT_UNIT(state, data) {
    state.settings_pricing_rules_default_unit = data
  },
  SETTINGS_PRICING_RULES_COMPUTE_TOTAL_COST_FOR(state, data) {
    state.settings_pricing_rules_compute_total_cost_for = data
  },
  SETTINGS_PRICING_RULES_LABOR_COST_BASED_ON(state, data) {
    state.settings_pricing_rules_labor_cost_based_on = data
  },
  SETTINGS_PRICING_RULES_EQUIP_COST_BASED_ON(state, data) {
    state.settings_pricing_rules_equip_cost_based_on = data
  },
  SETTINGS_PRICING_RULES_MISC_COST_BASED_ON(state, data) {
    state.settings_pricing_rules_misc_cost_based_on = data
  },
  SETTINGS_PRICING_RULES_PO_PRICE_ITEMS_PARTS(state, data) {
    state.settings_pricing_rules_po_price_items_parts = data
  },
  SETTINGS_PRICING_RULES_PO_PRICE_MISC(state, data) {
    state.settings_pricing_rules_po_price_misc = data
  },
  SETTINGS_PRICING_RULES_PO_PRICE_SUBRENT(state, data) {
    state.settings_pricing_rules_po_price_subrent = data
  },
  SETTINGS_PRICING_RULES_PO_PRICE_INVENTORY(state, data) {
    state.settings_pricing_rules_po_price_inventory = data
  },
  SET_SETTING_BARCODE_SCANNER_LIST(state, data) {
    state.fullfilBarcodeScannerList = data
  },
  SETTINGS_CATALOG_PRICING_RULES(state, data) {
    const mappedData = data.filter(item => item.is_active)
    state.settingsCatalogPricingRules = mappedData
  },
  SET_PRODUCT_UNIT_TYPE_LIST(state, data) {
    state.unitTypeList = data
  },
  SET_SUPPLIER_LIST(state, data) {
    state.supplierList = data
  },
  CUSTOMERS_LIST(state, data) {
    state.customersList = data
  },
  SET_ASSET_CONDITION_LIST(state, data) {
    state.assetConditionList = data
  },
  SET_BILLING_CONTACT_LIST(state, data) {
    state.billingContact = data.map(item => ({
      ...item,
      fullName: `${item.first_name} ${item.last_name}`,
    }))
  },
  SET_SHIPPING_CONTACT_LIST(state, data) {
    state.shippingContact = data.map(item => ({
      ...item,
      fullName: `${item.first_name} ${item.last_name}`,
    }))
  },
  SET_DISPATCH_AND_RETURN_ADDRESS_LIST(state, data) {
    state.dispatchAndReturnAddress = data.map(item => ({
      ...item,
      fullAddress: `${item.street}, ${item.city}, ${item.state.code}, ${item.country.three_letter}`,
    }))
  },
  SET_BILLING_CONTACT_ON_PURCHASE_LIST(state, data) {
    state.billingContactOnPurchase = data.map(item => ({
      ...item,
      fullName: `${item.first_name} ${item.last_name}`,
    }))
  },
  SET_INSURANCE_COMPANY_NAME_LIST(state, data) {
    state.insuranceCompanyNameList = data.map(item => item.insurance_company_name)
  },
  SET_OPERATION_HOURS_DISABLE_LIST(state, data) {
    const { closures, operation_hours } = data

    const disabled = []

    closures.forEach(closure => {
      if (closure.full_day) {
        const yesterday = new Date(closure.start_date)
        yesterday.setDate(yesterday.getDate() - 1)
        disabled.push({
          from: yesterday,
          to: new Date(closure.end_date),
        })
      }
      // DON'T DELETE THIS PIECE OF CODE ==>
      // else {
      //   const currentDay = new Date(closure.start_date)
      //   disabled.push(currentDay)
      // }
      // == == == == == == == == == ==  ==>
    })
    const dayOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    const weeks = operation_hours.filter(operationHour => operationHour.is_closed).map(x => dayOfWeek.indexOf(x.day))

    const func = date => weeks.includes(date.getDay())
    disabled.push(func)

    state.operationHoursDisableList = disabled

    state.isLoadingOperationHoursDisableList = true
  },
  SET_IS_LOADING_OPERATION_HOURS_DISABLE_LIST(state, bool) {
    state.isLoadingOperationHoursDisableList = bool
  },
  SET_WAREHOUSES_ADDRESS(state, data) {
    state.warehousesAddressList = data.map(item => ({
      id: item.id,
      fullAddress: `${item.street}, ${item.city}, ${item.state.code} ${item.country.three_letter}`,
    }))
  },
  ECOMMERCE_SHIPPING_METHOD(state, data) {
    state.ecommerceShippingMethod = data
  },
}
